
import { FormBlock } from "@/components/FormBuilder/types";

import {
  atlasPlatformMapActions,
  atlasPlatformMapMutations,
  atlasPlatformMapState
} from "@/store/modules/atlasPlatform";
import * as types from "@/store/mutation-types";
import Vue from "vue";
import { atlasPlatformFormSchema } from "../../../forms/admin/atlasPlatform";
export default Vue.extend({
  name: "createAtlasPlatform",
  data() {
    return {
      errorMessage: "",
      successMessage: "",
      isLoading: false,
      disablePrimaryButton: true,
      loadingText: ""
    };
  },
  created() {
    this.SET_EDIT({} as any);
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  },
  computed: {
    ...atlasPlatformMapState(["editing", "makingApiRequest"]),
    atlasPlatformFields(): FormBlock[] {
      return atlasPlatformFormSchema;
    }
  },
  methods: {
    validationChangeHandler(e: any) {
      const { formIsValid } = e;
      this.disablePrimaryButton = !formIsValid;
    },
    async formFieldChangeHandler({ key, value }: { key: any; value: any }) {
      this.editField({ key, value });
    },
    formFieldBlurHandler({ key, value }: { key: any; value: any }): void {
      this.editField({ key, value });
    },
    ...atlasPlatformMapMutations(["SET_EDIT"]),
    ...atlasPlatformMapMutations({ editField: types.SET_EDIT_FIELD }),
    ...atlasPlatformMapActions(["createAtlasPlatform"]),
    async toolbarSelectItem(event: string) {
      switch (event) {
        case "saveAtlasPlatform":
          await this.submit();
          break;
        case "cancelAtlasPlatform":
          this.$router.push("/admin/atlasPlatforms").catch(() => {});
          break;
      }
    },
    async submit(): Promise<void> {
      try {
        this.loadingText = "Creating platform. Please wait...";
        this.isLoading = true;
        await this.createAtlasPlatform({ name: this.editing?.name });
        this.$router.replace("/admin/atlasPlatforms");
        this.SET_EDIT({} as any);
      } catch (err) {
        this.errorMessage = err.message;
        this.$bugSnagClient.notify(err);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    }
  }
});
